import React, { useState } from 'react';
import Uploady, { useItemProgressListener } from "@rpldy/uploady";
import UploadButton from "@rpldy/upload-button";

const server = "https://computer.djboy.no"

const filterBySize = (file) => {
  //filter out images larger than 10MB
  return file.size <= 5242880*2;
};

const UploadProgress = () => {
  const [uploads, setUploads] = React.useState({});
  const progressData = useItemProgressListener();

  if (progressData && progressData.completed) {
      const upload = uploads[progressData.id] ||
          { name: progressData.url || progressData.file.name, progress: [0] };

      if (!~upload.progress.indexOf(progressData.completed)) {
          upload.progress.push(progressData.completed);

          setUploads({
              ...uploads,
              [progressData.id]: upload,
          });
      }
  }

  const progress = Object
                    .entries(uploads)
                    .map(([id, { progress }]) => {
                      return progress[progress.length -1];
                    });

  const done = progress[progress.length -1] === 100;

  if (done || progress.length === 0) {
    return null;
  }


  return (
    <div className="progress">
      {
        progress.map((p, i) => {
          if (p === 100) {
            return null;
          }

          return <div key={`progress-${i}`}><span>{p}</span></div>;
        })
      }
    </div>
  );
}

function App() {
  const [message, setMessage] = useState("");
  const [nick, setNick] = useState("");

    const sendSMS = e => {
      e.preventDefault()

     if (message === "") {
       alert("Du har ikke skrevet noen melding. Trykk på 3310-skjermen for å skrive.")
       return
     }

     if (message.length > 160) {
        alert("Meldingen din er for lang (maks 160 tegn)")
        return
     }

     if (nick.length > 10) {
      alert("Kallenavnet ditt er for langt (maks 10 tegn)")
      return
    }

     let tmpNick = nick;

     if (nick === "") {
       tmpNick = "Anonym"
     }

     const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ nick: tmpNick, message })
    };

    fetch(`${server}/sms`, requestOptions)
      .then(response => {
        if (response.status !== 200) {
          alert("error")
        }

        setMessage("")
      });
   }

   const onKeyDownHandler = e => {
    if (e.keyCode === 13) {
      sendSMS(e);
    }
  }

   const handleSMSChange = event => {
      setMessage(event.target.value)
  }

  const handleNickChange = event => {
    setNick(event.target.value)
  }

  return (
    <div className="oval-container">
    <p>Trykk på Nokia 3310-skjermen for å skrive melding. Husk, maks 160 tegn.</p>
    <p>Trykk SEND SMS for å sende uten bilde.</p>
    <p>Trykk SEND MMS for å legge ved bilde.</p>
    <div className="submit">
      <form onSubmit={sendSMS} className="sms">
          <span>Valgfritt kallenavn: </span>
          <input
            className="input-nick"
            nick="nick"
            placeholder="Valgfritt kallenavn (max 10 tegn)"
            value={nick}
            onChange={handleNickChange}
          />
          <br />
          <button type="submit">SEND SMS</button>
      </form>
      <Uploady
        className="mms"
        destination={{ url: `${server}/mms` }}
        fileFilter={filterBySize}
        accept="image/*"
        params={{ nick, message }}
        isSuccessfulCall={(xhr) => {
          setMessage("");
        }}
        sendWithFormData
      >
        <UploadButton
          className="input-image"
          multiple
          name="photos"
          method="POST"
          text="SEND MMS"
        />
        <UploadProgress />
      </Uploady>
    </div>
      <div className="nokia3310">
        <div className="nokia-head-container">
          <div className="speaker">
            <div className="hole"></div>
            <div className="hole"></div>
            <div className="hole"></div>
            <div className="hole"></div>
            <div className="hole"></div>
          </div>
          <div className="logo">NOKIA</div>
          <div className="screen-container">
              <input
                className="screen-input"
                sms="sms"
                placeholder="Trykk her"
                value={message}
                onChange={handleSMSChange}
                autoFocus
                onKeyDown={onKeyDownHandler}
              />
          </div>
          <div className="bottom-oval">
            <div className="big button top"></div>
          </div>
        </div>
        <div className="bottom-buttons">
          <div className="big button left">
            <span>C</span>
          </div>
          <div className="big button right">
            <span>&lt;</span>
            <span>&gt;</span>
          </div>
        </div>
        <div className="keyboard">
          <div className="button-key-container">
            <div className="button-key left">
              <span className="special">1</span>
              <span className="minitext compact">o_o</span>
            </div>
          </div>
          <div className="button-key-container">
            <div className="button-key middle">
              <span className="special">2</span>
              <span className="minitext">abc</span>
            </div>
          </div>
          <div className="button-key-container invert">
            <div className="button-key right">
              <span className="special">3</span>
              <span className="minitext">def</span>
            </div>
          </div>
          <div className="button-key-container">
            <div className="button-key left">
              <span className="special">4</span>
              <span className="minitext">ghi</span>
            </div>
          </div>
          <div className="button-key-container">
            <div className="button-key middle">
              <span className="special">5</span>
              <span className="minitext">jkl</span>
            </div>
          </div>
          <div className="button-key-container invert">
            <div className="button-key right">
              <span className="special">6</span>
              <span className="minitext">mno</span>
            </div>
          </div>
          <div className="button-key-container">
            <div className="button-key left">
              <span className="special">7</span>
              <span className="minitext">pqrs</span>
            </div>
          </div>
          <div className="button-key-container">
            <div className="button-key middle">
              <span className="special">8</span>
              <span className="minitext">tuv</span>
            </div>
          </div>
          <div className="button-key-container invert">
            <div className="button-key right">
              <span className="special">9</span>
              <span className="minitext">wxyz</span>
            </div>
          </div>
          <div className="button-key-container">
            <div className="button-key left">
              <span className="special">*</span>
              <span className="minitext">+</span>
            </div>
          </div>
          <div className="button-key-container">
            <div className="button-key middle">
              <span className="special">0</span>
              <span className="minitext rotate">[</span>
            </div>
          </div>
          <div className="button-key-container invert">
            <div className="button-key right">
              <span className="special">#</span>
              <span className="minitext home"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
